export const adaMojiLanding = () => {
  const svg = `
  <svg xmlns="http://www.w3.org/2000/svg" width="500" height="500" viewBox="0 0 100 100">
    <defs>
      <filter
        style="color-interpolation-filters:sRGB"
        id="filter1056"
        x="-0.022961739"
        width="1.0459235"
        y="-0.022961739"
        height="1.0459235">
        <feGaussianBlur
          stdDeviation="0.22659455"
          id="feGaussianBlur1058" />
      </filter>
      <filter
        style="color-interpolation-filters:sRGB"
        id="filter1056-6"
        x="-0.022961739"
        width="1.0459235"
        y="-0.022961739"
        height="1.0459235">
        <feGaussianBlur
          stdDeviation="0.22659455"
          id="feGaussianBlur1058-7" />
      </filter>
      <filter
        style="color-interpolation-filters:sRGB"
        id="filter1967"
        x="-0.091846955"
        width="1.1836939"
        y="-0.091846955"
        height="1.1836939">
        <feGaussianBlur
          stdDeviation="2.7119782"
          id="feGaussianBlur1969" />
      </filter>
      <filter
        style="color-interpolation-filters:sRGB"
        id="filter1975"
        x="-0.048833559"
        width="1.0976671"
        y="-0.048833559"
        height="1.0976671">
        <feGaussianBlur
          stdDeviation="1.4419155"
          id="feGaussianBlur1977" />
      </filter>
      <filter
        style="color-interpolation-filters:sRGB"
        id="filter1983"
        x="-0.192"
        width="1.384"
        y="-0.192"
        height="1.384">
        <feGaussianBlur
          stdDeviation="5.6692114"
          id="feGaussianBlur1985" />
      </filter>
    </defs>
    <g id="layer1">
      <g id="g1990" style="opacity:1;fill:#ff33ff">
        <circle
          style="filter:url(#filter1983)"
          id="path833"
          cx="50"
          cy="50"
          r="35.432571" />
        <circle
          style="filter:url(#filter1967)"
          id="path833-0"
          cx="64.079727"
          cy="64.965118"
          r="35.432571"
          transform="matrix(0.86511905,0,0,0.86511905,-5.4365881,-6.2025569)" />
        <circle
          style="filter:url(#filter1975)"
          id="path833-0-7"
          cx="64.079727"
          cy="64.965118"
          r="35.432571"
          transform="matrix(0.75198746,0,0,0.75198746,1.8128512,1.1470481)" />
      </g>
      <circle
        style="fill:#ffffff;fill-opacity:0.86;filter:url(#filter1056)"
        id="path980"
        cx="63.391449"
        cy="40"
        r="12" />
      <circle
        style="fill:#ffffff;fill-opacity:0.86;filter:url(#filter1056-6)"
        id="path980-1"
        cx="36"
        cy="40"
        r="12" />
      
      <circle
        style="fill:#4cc3ff;fill-opacity:1"
        id="path1092-2"
        cx="36"
        cy="40"
        r="8.5" />
      <circle
        style="fill:#4cc3ff;fill-opacity:1"
        id="path1092-2-7"
        cx="63.391449"
        cy="40"
        r="8.5" />
      <circle
        style="fill:#1a1a1a;fill-opacity:0.9"
        id="path1092"
        cx="36"
        cy="40"
        r="7" />
      <circle
        style="fill:#1a1a1a;fill-opacity:0.9"
        id="path1092-9"
        cx="63.391449"
        cy="40"
        r="7" />
      <circle
        style="fill:#f0f0f0;fill-opacity:1"
        id="path1191"
        cx="34.283531"
        cy="44.4"
        r="1.5" />
      <circle
        style="fill:#f0f0f0;fill-opacity:1"
        id="path1191-6"
        cx="61.67498"
        cy="44.4"
        r="1.5" />
      <path
        style="fill:none;stroke:#333333;stroke-width:1;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
        d="m 27.430798,58.43274 c 13.372607,19.535426 31.765797,19.535426 45.138404,0"
        id="path1549-6" />
    </g>
  </svg>
  `

  return svg
}
