import React, { useEffect, useMemo, useState } from 'react'

import { createRandomAdaMoji } from '../generator'
import { adaMojiLanding } from '../utils/baseImage'
import { SEO } from '../components/SEO'
import { Footer } from '../components/Footer'

const ADDRESS = 'addr1v9mhu2pwlgzmw7t2wt78q72wdt7fznqr0tw439vq28emf4celal9a'

interface TogglePillProp {
  label: string
  isSelected: boolean
  onPress: () => void
}

const TogglePill = ({ label, isSelected, onPress }: TogglePillProp) => {
  const className = isSelected ? 'toggle toggleSelected' : 'toggle'

  return (
    <div onClick={onPress} className={className}>
      {label}
    </div>
  )
}

export default function Home() {
  const [adaMoji, setAdaMoji] = useState<string | null>(null)

  const [useHearts, setUseHearts] = useState(false)
  const [useSweatDrop, setUseSweatDrop] = useState(false)
  const [useBlackBodyEdition, setUseBlackBodyEdition] = useState(false)
  const [useBlackEyeEdition, setUseBlackEyeEdition] = useState(false)
  const [useDifferentEyeColor, setUseDifferentEyeColor] = useState(false)

  useEffect(() => {
    const adaMoji = createRandomAdaMoji({})
    const encodedAdaMoji = encodeURIComponent(adaMoji)
    setAdaMoji(encodedAdaMoji)
  }, [])

  const generateNewAdaMoji = () => {
    const adaMoji = createRandomAdaMoji({
      useHearts,
      useSweatDrop,
      useBlackBodyEdition,
      useBlackEyeEdition,
      useDifferentEyeColor,
    })

    const encodedAdaMoji = encodeURIComponent(adaMoji)
    setAdaMoji(encodedAdaMoji)
  }

  const onPressUseHearts = () => {
    setUseHearts(!useHearts)
  }

  const onPressUseSweatDrop = () => {
    setUseSweatDrop(!useSweatDrop)
  }

  const onPressUseBlackBody = () => {
    setUseBlackBodyEdition(!useBlackBodyEdition)
  }

  const onPressUseBlackEye = () => {
    setUseBlackEyeEdition(!useBlackEyeEdition)
  }

  const onPressUseDifferentEyeColor = () => {
    setUseDifferentEyeColor(!useDifferentEyeColor)
  }

  const onPressCopyAddress = async () => {
    try {
      navigator.clipboard.writeText(ADDRESS)
    } catch (error) {
      console.log('Error: ', error)
    }
  }

  const logo = useMemo(() => {
    const adaLanding = adaMojiLanding()
    return encodeURIComponent(adaLanding)
  }, [])

  return (
    <>
      <SEO />

      <div className="landingPage">
        <div className="landingPageContainer">
          <div className="landingPageLogoAndTextContainer">
            <div className="logoContainer">
              <img
                src={`data:image/svg+xml;utf8, ${logo}`}
                style={{ maxHeight: '100%', maxWidth: '100%' }}
              />
            </div>
            <div>
              <div className="adaMojiText">AdaMoji</div>
              <div className="adaMojiSubtitle">World first randomly generated NFT</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="sectionContainer">
          <div className="sectionHeading">What is AdaMoji?</div>

          <p className="sectionParagraph">AdaMoji is first randomly generated NFT in the world.</p>
          <p className="sectionParagraph">
            AdaMoji NFT is minted on <span className="bold">Cardano</span> blockchain.
          </p>
        </div>

        <div className="sectionContainer">
          <div className="sectionHeading">How it works?</div>

          <div className="stepContainer">
            <p className="sectionStep">STEP 1:</p>
            <p className="sectionParagraph">
              Send <span className="bold">7</span>, <span className="bold">25</span> or{' '}
              <span className="bold">100</span> <span className="bold">ADA</span> coins.
            </p>
            <div className="descriptionContainer">
              <div className="adaPriceExplanation">
                7 ADA: randomly AdaMoji, no special attribute guaranteed
              </div>
              <div className="adaPriceExplanation">
                25 ADA: guaranteed that AdaMoji will have at least HEARTS or SWEAT DROP
              </div>
              <div>
                100 ADA: guaranteed that AdaMoji will have at least BLACK BODY or BLACK EYES or
                DIFFERENT EYE COLOR
              </div>
            </div>

            <div className="descriptionContainer">
              Never send ADA from exchanges, always use your wallet (AdaLite, Yoroi, Daedalus)
            </div>
          </div>

          <div className="stepContainer">
            <p className="sectionStep">STEP 2:</p>
            <p className="sectionParagraph">AdaMoji NFT is randomly generated.</p>
          </div>

          <div className="stepContainer">
            <p className="sectionStep">STEP 3:</p>
            <p className="sectionParagraph">
              In few minutes (15 min) you receive AdaMoji NFT and{' '}
              <span className="bold">2 ADA</span> back to your address.
            </p>
          </div>

          <div className="adaAddressHeader">ADDRESS where to send ADA</div>
          <div className="adaAddressContainer">
            <div className="adaAddress">
              <p className="adaAddressText">{ADDRESS}</p>
            </div>
            <div>
              <div className="btn" onClick={onPressCopyAddress}>
                COPY
              </div>
            </div>
          </div>
          <div className="addressQrCodeContainer">
            <div className="addressQrCode" />
          </div>
        </div>

        <div className="sectionContainer">
          <div className="sectionHeading">Rareness</div>

          <div className="descriptionContainer">
            <div className="rarenessSection">
              <div className="rarenessRaw">BODY COLOR: 360 different colors</div>
              <div className="rarenessRaw">
                BODY OPACITY: 15% chance to get opacity for body color
              </div>
            </div>

            <div className="rarenessSection">
              <div className="rarenessRaw">EYE COLOR: 360 different colors</div>
              <div className="rarenessRaw">
                EYE OPACITY: 15% chance to get opacity for eye color
              </div>
            </div>

            <div className="rarenessSection">
              <div className="rarenessRaw">SMILE: 6 of 10 (60%)</div>
              <div className="rarenessRaw">NO EMOTION: 2 of 10 (20%)</div>
              <div className="rarenessRaw">SAD: 2 of 10 (20%)</div>
            </div>

            <div className="rarenessSection">
              <div className="rarenessRaw">HEARTS: 1 of 50 (2%)</div>
              <div className="rarenessRaw">SWEAT DROP: 1 of 50 (2%)</div>
              <div className="rarenessRaw rarenessDescription">
                - AdaMoji can have only HEARTS or SWEAT DROP (not both)
              </div>
            </div>

            <div className="rarenessRaw">BLACK BODY: 1 of 1000 (0.1%)</div>
            <div className="rarenessRaw">BLACK EYES: 1 of 1000 (0.1%)</div>
            <div className="rarenessRaw">DIFFERENT EYES COLOR: 1 of 1000 (0.1%)</div>
          </div>

          <div className="togglesContainer">
            <TogglePill label="HEARTS" onPress={onPressUseHearts} isSelected={useHearts} />
            <TogglePill
              label="SWEAT DROP"
              onPress={onPressUseSweatDrop}
              isSelected={useSweatDrop}
            />
            <TogglePill
              label="BLACK BODY"
              onPress={onPressUseBlackBody}
              isSelected={useBlackBodyEdition}
            />
            <TogglePill
              label="BLACK EYES"
              onPress={onPressUseBlackEye}
              isSelected={useBlackEyeEdition}
            />
            <TogglePill
              label="DIFFERENT EYES COLOR"
              onPress={onPressUseDifferentEyeColor}
              isSelected={useDifferentEyeColor}
            />
          </div>

          <div>
            <div className="btn" onClick={generateNewAdaMoji}>
              Random AdaMoji
            </div>
          </div>

          {adaMoji !== null && (
            <div className="randomAdaMoji">
              <img
                src={`data:image/svg+xml;utf8, ${adaMoji}`}
                style={{ maxHeight: '100%', maxWidth: '100%' }}
              />
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  )
}
