import React, { useMemo } from 'react'

import { FaDiscord } from 'react-icons/fa'
import { FaTwitter } from 'react-icons/fa'
import { FaMedium } from 'react-icons/fa'

const DISCORD_LINK = 'https://discord.gg/n25VCvVa3d'
const MEDIUM_LINK = 'https://medium.com/@adamoji'
const TWITTER_LINK = 'https://twitter.com/AdaMojiIO'

const BEGINNING_YEAR = 2021

export const Footer = () => {
  const copyrightYears = useMemo(() => {
    const currentYear = new Date().getFullYear()
    if (currentYear > BEGINNING_YEAR) {
      return `${BEGINNING_YEAR} - ${currentYear}`
    } else {
      return BEGINNING_YEAR
    }
  }, [])

  return (
    <footer>
      <div className="footerIconContainer">
        <a target="_blank" rel="noopener noreferrer" href={TWITTER_LINK}>
          <FaTwitter className="footerIcon" />
          <div className="footerIconText">
            Follow AdaMoji
            <br />
            on Twitter
          </div>
        </a>
      </div>

      <div className="footerIconContainer">
        <a target="_blank" rel="noopener noreferrer" href={DISCORD_LINK}>
          <FaDiscord className="footerIcon" />
          <div className="footerIconText">Join on Discord</div>
        </a>
      </div>

      <div className="footerIconContainer">
        <a target="_blank" rel="noopener noreferrer" href={MEDIUM_LINK}>
          <FaMedium className="footerIcon" />
          <div className="footerIconText">
            Follow AdaMoji
            <br />
            on Medium
          </div>
        </a>
      </div>

      <div className="footerCopyright">Copyright {copyrightYears} by AdaMoji</div>
    </footer>
  )
}
