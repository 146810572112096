import convert from 'color-convert'

const MOUTH_SMILE = 'm 27.430798,58.43274 c 13.372607,19.535426 31.765797,19.535426 45.138404,0'
const MOUTH_STRAIGHT = 'M 36.791742,66.849048 H 63.208258'
const MOUTH_SAD = 'm 67.594217,70.350815 c -10.424851,-10.141798 -24.763582,-10.141798 -35.188433,0'

const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min) //The maximum is exclusive and the minimum is inclusive
}

// const getRandomIntInclusive = (min: number, max: number) => {
//   min = Math.ceil(min)
//   max = Math.floor(max)
//   return Math.floor(Math.random() * (max - min + 1) + min) //The maximum is inclusive and the minimum is inclusive
// }

const checkIsProbable = (probabilityInPercentage: number) => {
  const base = 1000000
  const percentage = probabilityInPercentage / 100
  const breakPoint = base * percentage
  const randomNumber = getRandomInt(0, base)

  return randomNumber < breakPoint
}

const getBodyColor = ({ isBlackEdition = false }) => {
  if (isBlackEdition) {
    const MOUTH_COLOR = '#d3d3d3'
    const randomNumberForBody = getRandomInt(0, 20)
    const bodyColor = convert.hsl.hex([0, 0, randomNumberForBody])
    const BODY_COLOR = `#${bodyColor}`
    console.log('black body color: ', BODY_COLOR)

    return {
      bodyColor: BODY_COLOR,
      mouthColor: MOUTH_COLOR,
    }
  } else {
    const MOUTH_COLOR = '#333333'
    const randomNumberForBody = getRandomInt(0, 360)
    const bodyColor = convert.hsl.hex([randomNumberForBody, 100, 60])
    const BODY_COLOR = `#${bodyColor}`
    console.log('body color: ', BODY_COLOR)

    return {
      bodyColor: BODY_COLOR,
      mouthColor: MOUTH_COLOR,
    }
  }
}

interface GetBodyOpacity {
  hasBodyOpacity: boolean
}

const getBodyOpacity = ({ hasBodyOpacity }: GetBodyOpacity) => {
  if (hasBodyOpacity) {
    const randomNumberForBodyOpacity = getRandomInt(10, 100)
    const randomBodyOpacity = randomNumberForBodyOpacity / 100
    console.log('randomBodyOpacity: ', randomBodyOpacity)
    return randomBodyOpacity
  }

  return 1
}

interface GetEyeColor {
  isBlackEdition: boolean
}

const getEyeColor = ({ isBlackEdition }: GetEyeColor) => {
  if (isBlackEdition) {
    const randomNumberEye = getRandomInt(20, 70)
    const eyeColor = convert.hsl.hex([0, 0, randomNumberEye])
    return `#${eyeColor}`
  } else {
    const randomNumberEye = getRandomInt(0, 360)
    const eyeColor = convert.hsl.hex([randomNumberEye, 100, 65])
    return `#${eyeColor}`
  }
}

interface GetEyesColor {
  isBlackEdition: boolean
  hasDifferentEyeColor: boolean
}

const getEyesColor = ({ isBlackEdition, hasDifferentEyeColor }: GetEyesColor) => {
  if (hasDifferentEyeColor) {
    const LEFT_EYE_COLOR = getEyeColor({ isBlackEdition })
    let RIGHT_EYE_COLOR = getEyeColor({ isBlackEdition })
    while (RIGHT_EYE_COLOR === LEFT_EYE_COLOR) {
      RIGHT_EYE_COLOR = getEyeColor({ isBlackEdition })
    }

    console.log('left eye color: ', LEFT_EYE_COLOR)
    console.log('right eye color: ', RIGHT_EYE_COLOR)

    return {
      leftEyeColor: LEFT_EYE_COLOR,
      rightEyeColor: RIGHT_EYE_COLOR,
    }
  } else {
    const EYE_COLOR = getEyeColor({ isBlackEdition })
    console.log('eye color: ', EYE_COLOR)

    return {
      leftEyeColor: EYE_COLOR,
      rightEyeColor: EYE_COLOR,
    }
  }
}

interface GetEyeOpacity {
  hasEyeOpacity: boolean
}

const getEyeOpacity = ({ hasEyeOpacity }: GetEyeOpacity) => {
  if (hasEyeOpacity) {
    const randomNumberEyeOpacity = getRandomInt(0, 100)
    const randomEyeOpacity = randomNumberEyeOpacity / 100
    console.log('randomEyeOpacity: ', randomEyeOpacity)
    return randomEyeOpacity
  }

  return 1
}

const getMouthPath = () => {
  const LEVEL_1 = 20
  const LEVEL_2 = 40

  const randomNumberMouth = getRandomInt(0, 100)

  if (randomNumberMouth >= 0 && randomNumberMouth < LEVEL_1) {
    return MOUTH_SAD
  } else if (randomNumberMouth >= LEVEL_1 && randomNumberMouth < LEVEL_2) {
    return MOUTH_STRAIGHT
  } else {
    return MOUTH_SMILE
  }
}

interface GetSweatDrop {
  hasSweatDrop: boolean
}

const getSweatDrop = ({ hasSweatDrop }: GetSweatDrop) => {
  if (hasSweatDrop) {
    const randomNumberSweatDrop = getRandomInt(0, 360)
    const sweatDropColor = convert.hsl.hex([randomNumberSweatDrop, 100, 65])
    const SWEAT_DROP_COLOR = `#${sweatDropColor}`

    return `
      <path
        style="opacity:0.9;fill:${SWEAT_DROP_COLOR};fill-opacity:1;fill-rule:evenodd;stroke:none;stroke-width:0.426742;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
        d="m 81.602546,24.667231 c 0,0 6.394333,8.576273 5.186331,13.121617 -0.568698,2.139835 -2.85804,4.150088 -5.186284,4.150091 -2.328244,2e-6 -4.617624,-2.010268 -5.186329,-4.15012 -1.208008,-4.545329 5.186282,-13.121588 5.186282,-13.121588 z"
        id="sweatDrop" />
    `
  } else {
    return ''
  }
}

interface GetHearts {
  hasHearts: boolean
}

const getHearts = ({ hasHearts }: GetHearts) => {
  if (hasHearts) {
    const randomNumberSweatDrop = getRandomInt(0, 360)
    const heartsColor = convert.hsl.hex([randomNumberSweatDrop, 100, 65])
    const HEARTS_COLOR = `#${heartsColor}`

    const style = `fill:${HEARTS_COLOR};fill-opacity:1;fill-rule:evenodd;stroke:none;stroke-width:0;stroke-linecap:round;stroke-linejoin:round`

    return `
      <path
        style="${style}"
        d="m 82.346202,16.381361 c -1.052165,-0.607468 -2.337159,-0.811786 -3.608845,-0.471037 -2.543369,0.681494 -4.042288,3.277698 -3.360794,5.821068 l 0.05979,0.223145 c 2.460335,9.181762 2.460335,9.181762 2.460335,9.181762 0,0 0,0 9.181803,-2.460171 l 0.223142,-0.0598 c 2.543372,-0.681493 4.042289,-3.277696 3.360796,-5.821067 -0.681495,-2.543371 -3.277693,-4.042288 -5.821063,-3.360792 l -0.223147,0.05978 -0.0598,-0.223146 C 84.217683,17.999425 83.398352,16.98881 82.346203,16.381352 Z"
        id="heart1" />
      <path
        style="${style}"
        d="m 6.5688096,42.851519 c -0.9296813,0.536752 -1.6537329,1.429778 -1.9548131,2.553427 -0.6021602,2.247297 0.7222693,4.541276 2.9695654,5.143438 l 0.1971713,0.05283 c 8.1129528,2.173782 8.1129528,2.173782 8.1129528,2.173782 0,0 0,0 2.173925,-8.112914 l 0.05283,-0.197165 c 0.602163,-2.247297 -0.722268,-4.541277 -2.969564,-5.143439 -2.247298,-0.602161 -4.541273,0.722266 -5.143434,2.969562 l -0.05283,0.197169 -0.197171,-0.05283 c -1.123653,-0.301081 -2.2589637,-0.120604 -3.1886325,0.41614 z"
        id="heart2" />
      <path
        style="${style}"
        d="m 70.908368,73.297168 c -0.93271,0 -1.865469,0.357407 -2.580155,1.072094 -1.429372,1.429374 -1.429372,3.730829 0,5.160203 l 0.125404,0.125404 c 5.160203,5.160111 5.160203,5.160111 5.160203,5.160111 0,0 0,0 5.160202,-5.160111 l 0.125405,-0.125404 c 1.429372,-1.429374 1.429372,-3.730829 0,-5.160203 -1.429373,-1.429373 -3.730826,-1.429373 -5.160197,0 l -0.125419,0.125405 -0.125404,-0.125405 C 72.773721,73.654575 71.841059,73.297168 70.90836,73.297168 Z"
        id="heart3" />
    `
  }

  return ''
}

interface CreateRandomAdaMoji {
  useHearts?: boolean
  useSweatDrop?: boolean
  useBlackBodyEdition?: boolean
  useBlackEyeEdition?: boolean
  useDifferentEyeColor?: boolean
}

export const createRandomAdaMoji = ({
  useBlackBodyEdition,
  useBlackEyeEdition,
  useDifferentEyeColor,
  useHearts,
  useSweatDrop,
}: CreateRandomAdaMoji) => {
  const hasBodyOpacity = checkIsProbable(15)
  const hasEyeOpacity = checkIsProbable(15)

  let hasHearts = useHearts ? true : checkIsProbable(2)
  let hasSweatDrop = useSweatDrop ? true : checkIsProbable(2)
  if (hasHearts && hasSweatDrop) {
    const hearts = checkIsProbable(50)
    if (hearts) {
      hasSweatDrop = false
    } else {
      hasHearts = false
    }
  }

  const isBlackBodyEdition = useBlackBodyEdition ? true : checkIsProbable(0.1)
  const isBlackEyeEdition = useBlackEyeEdition ? true : checkIsProbable(0.1)
  const hasDifferentEyeColor = useDifferentEyeColor ? true : checkIsProbable(0.1)

  const { bodyColor, mouthColor } = getBodyColor({
    isBlackEdition: isBlackBodyEdition,
  })
  const bodyOpacity = getBodyOpacity({ hasBodyOpacity })
  const { leftEyeColor, rightEyeColor } = getEyesColor({
    isBlackEdition: isBlackEyeEdition,
    hasDifferentEyeColor,
  })
  const eyeOpacity = getEyeOpacity({ hasEyeOpacity })

  const mouthPath = getMouthPath()
  const sweatDrop = getSweatDrop({ hasSweatDrop })
  const hearts = getHearts({ hasHearts })

  const svg = `
  <svg xmlns="http://www.w3.org/2000/svg" width="500" height="500" viewBox="0 0 100 100">
    <defs>
      <filter
        style="color-interpolation-filters:sRGB"
        id="filter1056"
        x="-0.022961739"
        width="1.0459235"
        y="-0.022961739"
        height="1.0459235">
        <feGaussianBlur
          stdDeviation="0.22659455"
          id="feGaussianBlur1058" />
      </filter>
      <filter
        style="color-interpolation-filters:sRGB"
        id="filter1056-6"
        x="-0.022961739"
        width="1.0459235"
        y="-0.022961739"
        height="1.0459235">
        <feGaussianBlur
          stdDeviation="0.22659455"
          id="feGaussianBlur1058-7" />
      </filter>
      <filter
        style="color-interpolation-filters:sRGB"
        id="filter1967"
        x="-0.091846955"
        width="1.1836939"
        y="-0.091846955"
        height="1.1836939">
        <feGaussianBlur
          stdDeviation="2.7119782"
          id="feGaussianBlur1969" />
      </filter>
      <filter
        style="color-interpolation-filters:sRGB"
        id="filter1975"
        x="-0.048833559"
        width="1.0976671"
        y="-0.048833559"
        height="1.0976671">
        <feGaussianBlur
          stdDeviation="1.4419155"
          id="feGaussianBlur1977" />
      </filter>
      <filter
        style="color-interpolation-filters:sRGB"
        id="filter1983"
        x="-0.192"
        width="1.384"
        y="-0.192"
        height="1.384">
        <feGaussianBlur
          stdDeviation="5.6692114"
          id="feGaussianBlur1985" />
      </filter>
    </defs>
    <g id="layer1">
      <g id="g1990" style="opacity:${bodyOpacity};fill:${bodyColor}">
        <circle
          style="filter:url(#filter1983)"
          id="path833"
          cx="50"
          cy="50"
          r="35.432571" />
        <circle
          style="filter:url(#filter1967)"
          id="path833-0"
          cx="64.079727"
          cy="64.965118"
          r="35.432571"
          transform="matrix(0.86511905,0,0,0.86511905,-5.4365881,-6.2025569)" />
        <circle
          style="filter:url(#filter1975)"
          id="path833-0-7"
          cx="64.079727"
          cy="64.965118"
          r="35.432571"
          transform="matrix(0.75198746,0,0,0.75198746,1.8128512,1.1470481)" />
      </g>
      <circle
        style="fill:#ffffff;fill-opacity:0.86;filter:url(#filter1056)"
        id="path980"
        cx="63.391449"
        cy="40"
        r="12" />
      <circle
        style="fill:#ffffff;fill-opacity:0.86;filter:url(#filter1056-6)"
        id="path980-1"
        cx="36"
        cy="40"
        r="12" />
      
      <circle
        style="fill:${rightEyeColor};fill-opacity:${eyeOpacity}"
        id="path1092-2"
        cx="36"
        cy="40"
        r="8.5" />
      <circle
        style="fill:${leftEyeColor};fill-opacity:${eyeOpacity}"
        id="path1092-2-7"
        cx="63.391449"
        cy="40"
        r="8.5" />
      <circle
        style="fill:#1a1a1a;fill-opacity:0.9"
        id="path1092"
        cx="36"
        cy="40"
        r="7" />
      <circle
        style="fill:#1a1a1a;fill-opacity:0.9"
        id="path1092-9"
        cx="63.391449"
        cy="40"
        r="7" />
      <circle
        style="fill:#f0f0f0;fill-opacity:1"
        id="path1191"
        cx="34.283531"
        cy="44.4"
        r="1.5" />
      <circle
        style="fill:#f0f0f0;fill-opacity:1"
        id="path1191-6"
        cx="61.67498"
        cy="44.4"
        r="1.5" />
      <path
        style="fill:none;stroke:${mouthColor};stroke-width:1;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:0.9"
        d="${mouthPath}"
        id="mouth1" />
      ${sweatDrop}
      ${hearts}
    </g>
  </svg>
  `

  return svg
}
